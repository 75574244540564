/* You can add global styles to this file, and also import other style files */

:root {
  --dark-madfu: #101440;
  --Text-Main-Header: #242323;
  --light-dark: #292d32;
  --Text-Main-Header-2: #1c1e1b;
  --Text-Sub-Head-Text: #4d4d4d;
  --Accent-Blue: #b2e2ff;
  --Error-Red: #f64650;
  --Error-Red-border: #e63d24;
  --New-Main-Colors-New-Main: #100ca1;
  --Tab-BGs-Blue-Tab: #e8f4f9;
  --Basic-600: #8f9bb3;
  --text-color-default-dark: #222b45;
  --Text-Paragraph: #666;
  --Accent-Low-Op-Low-Contrasr-Grey: #e0e0e0;
  --Field-Icon-color: #bcc1c6;
  --text: rgba(0, 0, 0, 0.84);
  --Tab-BGs-Grey-Tab: #f7f9fc;
  --Text-Small-Title: #333;
  --Success-Green: #27ae60;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf");
  font-weight: 700;
}

html,
body {
  margin: 0px;
  height: 100%;
  background: transparent;
}

html,
body,
* {
  font-family: "Degular", sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

html[lang="ar"] *,
html[lang="ar"] label {
  font-family: "IBM Plex Sans Arabic", sans-serif;

  .change-language-btn {
    font-family: "Degular", sans-serif !important;
  }
}

html[lang="en"] *,
html[lang="en"] label {
  font-family: "Degular", sans-serif;
}

:dir(rtl) * {
  direction: rtl;
}

p {
  margin: 0;
}

.cancel-button {
  background-color: white !important;
  font-weight: 400;
  color: rgba(143, 155, 179, 1) !important;
  font-size: 14px;
  border: none;
  margin: 0 auto;
  display: block;
}

.cancel-button:hover {
  color: rgba(143, 155, 179, 1);
}

.modal-content {
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 40px #00153a4d;
  border-radius: 14px !important;
  opacity: 1;
}

.change-language-btn {
  color: var(--dark-madfu);
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-top: 22px;
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
  padding: 0;

  &:focus {
    border: none;
  }
}

form {
  input {
    height: 64px;
    border: none;
    font-size: 20px;

    &:focus-visible {
      outline: none;
    }

    &.red-border {
      border: 1px solid var(--Error-Red-border) !important;
      box-shadow: 0px 0px 5px 0px rgba(246, 70, 80, 0.52) !important;
    }

    &.green-border {
      border: 1px solid var(--Success-Green) !important;
      box-shadow: 0px 0px 5px 0px rgba(39, 174, 96, 0.52) !important;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 20px;
      color: #c1bcc6;
    }
  }

  .error-message {
    color: var(--Error-Red);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .success-message {
    color: var(--Success-Green);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }
}

button {
  border: none !important;

  &:disabled {
    background: var(--Accent-Low-Op-Low-Contrasr-Grey) !important;
    color: var(--Field-Icon-color) !important;
  }
}

.curser-pointer {
  cursor: pointer;
}

/* custom radio button */
.container-radio {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    /* Hide the browser's default radio button */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  /* Create a custom radio button */
  .checkmark {
    position: relative;
    display: block;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--New-Main-Colors-New-Main);

    &::after {
      /* Create the indicator (the dot/circle - hidden when not checked) */
      content: "";
      position: absolute;
      display: none;

      /* Style the indicator (dot/circle) */
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--New-Main-Colors-New-Main);
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #f6f6f6;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  &.disabled {
    &:hover input ~ .checkmark {
      background-color: transparent;
    }

    input:disabled {
      cursor: auto;

      & ~ .checkmark {
        cursor: auto;
        background-color: transparent;
        border: 1px solid var(--Field-Icon-color);
      }
    }
  }
}

/* custom check box */
.check-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: var(--dark-madfu) !important;
  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;

  /* Create a custom checkbox */
  .checkmark {
    display: inline-block;
    height: 42px;
    width: 42px;
    background-color: var(--Tab-BGs-Grey-Tab);
    border-radius: 5px;
    margin-inline-end: 32px;
    border: 1px solid var(--Accent-Low-Op-Low-Contrasr-Grey);
    box-shadow: 0px 2.535px 2.535px -1.267px rgba(74, 74, 104, 0.1) inset;
    position: relative;

    &::after {
      inset-inline-start: 18px;
      position: absolute;
      top: 10px;
      width: 8px;
      height: 17px;
      border: solid white;
      border-width: 0px 2px 2px 0;
      content: "";
      display: none;
      transform: rotate(45deg);
    }
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute !important;
    opacity: 0;
    cursor: pointer;
    height: 0 !important;
    width: 0 !important;
  }

  input:checked ~ .checkmark {
    background-color: #31d0aa;

    &::after {
      display: block;
    }
  }

  &.disabled {
    cursor: auto;

    &:hover input ~ .checkmark {
      background-color: transparent;
    }

    input:disabled {
      cursor: auto;

      & ~ .checkmark {
        cursor: auto;
        background-color: transparent;
        border: 1px solid var(--Field-Icon-color);
      }
    }
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

@media screen and (max-width: 991.98px) {
  .change-language-btn {
    font-size: 12px;
  }

  input {
    font-size: 12px;
    height: 46px;
  }

  .container-radio {
    height: 20px;
    width: 20px;

    input {
      height: 20px;
      width: 20px;
    }

    .checkmark {
      height: 20px;
      width: 20px;

      &::after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .check-box-container {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;

    .checkmark {
      height: 18px;
      width: 18px;
      border-radius: 3px;
      margin-inline-end: 14px;

      &::after {
        inset-inline-start: 6px;
        top: 4px;
        width: 5px;
        height: 8px;
      }
    }
  }
}

:dir(rtl) {
  @media screen and (max-width: 991.98px) {
    .check-box-container {
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.2px;
    }
  }
}
